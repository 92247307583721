<template>
  <div class="about-us">
    <div class="about-us-banner">
      <img :src="bannerImg" style="width: 100%" />
      <div class="about-us-banner-title">
        <span>趴趴英语 一站式线上留学语培平台</span>
      </div>
      <div class="about-us-banner-content">
        <span> PAPA ENGLISH </span>
      </div>
    </div>
    <div class="about-us-introduce">
      <div class="about-us-introduce-title">品牌故事</div>
      <div class="about-us-introduce-desc">BRAND STORY</div>
      <div class="about-us-introduce-wrap">
        <div class="about-us-introduce-left">
          <div class="about-us-introduce-content">
            趴趴教育成立于2014年，是一个专注进行互联网在线教育的留学考试培训&留学申请一站式服务品牌。<br />
            趴趴教育的核心团队有多名海归留学生，我们深深体会过中国留学生远赴海外读书的不易和艰辛。创立趴趴教育品牌的初衷，就是希望能让更多的同学在留学路上走得更顺利，帮助大家能够圆梦理想的院校。<br />
            「趴趴」的名字由一个「足」、一个「八」组成，寓意各位考生能在趴趴的帮助下，一步一步地走向雅思八分。同时，我们也希望借着当下的互联网力量，帮助每一位考生突破时间和空间限制，随时随地学习。无论在家里、学习、还是咖啡馆，站着、坐着、甚至趴着，都可以轻松通过语言考试，能够享受到优秀的师资力量和优质的教学课程，从而圆梦留学。<br />
            趴趴教育目前开设有雅思、托福、GRE、GMAT、PTE、实用英语类的学习课程产品以及英国，澳洲，新西兰、新加坡和中国香港院校的留学申请服务。趴趴成立多年来，已帮助诸多学员成功圆梦理想院校，为他们留学之路添砖加瓦，深受广大学员认可和好评。<br />
          </div>
        </div>
        <div class="about-us-introduce-right">
          <div class="about-us-introduce-content">
            迄今为止，趴趴作为国内较早扎根于互联网教育的品牌之一，一直以来广受学员好评。趴趴教育的官方淘宝店铺是获得淘宝官方认证的10年老店、天猫店铺则是7年老店。真金不怕火炼，在第三方平台的统计数据反馈上，趴趴教育产品的各项评分都高于同行业的平均水平。趴趴教育的官方淘宝店铺是获得淘宝官方认证的10年老店、天猫店铺则是8年老店。真金不怕火炼，在第三方平台的统计数据反馈上，趴趴教育产品的各项评分都高于同行业的平均水平。目前趴趴全网关注数超100W，淘宝好评率更是高达99.5%！
          </div>
          <div class="about-us-introduce-img">
            <div class="tmall">
              <img :src="bannerImg1" />
              <img :src="bannerImg2" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about-us-introduce">
      <div class="about-us-introduce-title">我们的师资</div>
      <div class="about-us-introduce-desc">TEACHER TEAM</div>
      <div class="about-us-introduce-wrap">
        <div class="about-us-introduce-left">
          <div class="about-us-introduce-img">
            <img :src="teacherImg" />
          </div>
        </div>
        <div class="about-us-introduce-right">
          <div class="about-us-introduce-top-title">
            「学习，重要的不是找到人，而是找对人」
          </div>
          <div class="about-us-introduce-content">
            当今的互联网，由于信息爆炸，大家在学习上不再愁自己找不到老师，但是很多同学正面临「如何找到对的老师」的问题。而这一个问题，可以在趴趴得到完美解决。
            趴趴的师资团队由雅思英籍前考官Paul
            Winterbottom老师亲自坐镇领衔，集聚雅思/托福/GRE/GMAT/PTE等领域内全球资深名师，平均教学经验8+年，定期参加官方培训，能够解决各阶段备考学员不同问题，针对性定制教学方案，帮助考生实现全方位高效提升。当今的互联网，由于信息爆炸，大家在学习上不再愁自己找不到老师，但是很多同学正面临「如何找到对的老师」的问题。而这一个问题，可以在趴趴得到完美解决。
            趴趴的师资团队由雅思英籍前考官Paul
            Winterbottom老师亲自坐镇领衔，集聚雅思/托福/GRE/GMAT/PTE等领域内全球资深名师，平均教学经验8+年，定期参加官方培训，能够解决各阶段备考学员不同问题，针对性定制教学方案，帮助考生实现全方位高效提升。<br />
            长期的教学实践，使我们打造出了一套科学高效的课程产品体系。不同的学习产品及课程类型，可以满足不同阶段不同基础考生的备考需求。在帮助您提分这件事，趴趴是专业的。<br />
          </div>
        </div>
      </div>
    </div>
    <div class="about-us-introduce">
      <div class="about-us-introduce-title">我们的团队</div>
      <div class="about-us-introduce-desc">OUR TEAM</div>
      <div class="about-us-introduce-wrap">
        <div
          class="about-us-introduce-left"
          style="
						width: 362px;
						border: 1px solid #e5e5e5;
						padding: 21px 28px 16px 28px;
						margin-right: 20px;
					"
        >
          <div class="about-us-img-info">
            <div class="about-us-img-content">
              作为服务于广大留学生的互联网教育企业，我们十分重视我们的团队素质。趴趴教育的创始团队和核心成员大部分毕业于帝国理工学院、纽约大学、伦敦政治经济学院、曼彻斯特大学、复旦大学、上海财经大学等海内外名校。同样的求学经历使我们对于准留学生们的需求感同身受，所为也为我们能够更好地服务大家打下了坚实的基础。
            </div>
            <div class="about-us-img">
              <img :src="teampic1" />
            </div>
            <div class="about-us-img-desc">
              *中国台湾新闻媒体曾到访趴趴英语办公室实地采访优秀同事王同学
            </div>
            <div class="about-us-img-desc">
              （上财本科保研北大研究生）
            </div>
          </div>
        </div>
        <div class="about-us-introduce-middle" style="width: 530px">
          <div class="about-us-introduce-middle-top">
            <div>
              <div>
                趴趴教育总部地址位于国家经济金融中心上海市的中心城区普陀区，办公室地址设在真北路958号天地科技广场。<br />
                如果大家想来和趴趴面对面聊聊你的备考问题和留学问题，我们随时欢迎参观哦～<br />
              </div>
            </div>
          </div>
          <div class="about-us-introduce-middle-bottom">
            <img :src="teampic2" alt="" />
            <img :src="teampic3" alt="" />
          </div>
        </div>
        <div
          class="about-us-introduce-right"
          style="width: 210px; margin-left: 20px"
        >
          <img
            :src="teampic6"
            alt=""
            style="margin-bottom: 18px; font-size: 0px"
          />
          <img
            :src="teampic7"
            alt=""
            style="margin-bottom: 18px; font-size: 0px"
          />
          <img :src="teampic8" alt="" />
        </div>
      </div>
    </div>

    <div class="about-us-introduce">
      <div class="about-us-introduce-title">我们的社会活动</div>
      <div class="about-us-introduce-desc">OUR SOCIAL ACTIVITIES</div>
      <div class="about-us-introduce-wrap">
        <swiper
          :options="swiperOption"
          @mouseenter.native.stop="mouseOverSlider"
          @mouseleave.native.stop="mouseLeaveSlider"
          ref="indexSwiper"
        >
          <swiper-slide
            v-for="(slide, index) in activities"
            :key="index"
          >
            <div class="swiper-bottom-info" @click="getMax(slide)">
              <img :src="slide.img" alt="" />
              <div class="swiper-bottom-content">
                {{ slide.desc }}
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    <!--点击放大的弹框-->
    <div class="wrap-max-bg" v-if="isShow" @click="closeDialog"></div>
    <div class="wrap-max" v-if="isShow">
      <img :src="showImg" class="maxImg" />
      <div class="leftArrow" @click="leftclick(params)">
        <img :src="arrowLeft" />
      </div>
      <div class="rightArrow" @click="rightclick(params)">
        <img :src="arrowRight" />
      </div>
      <img :src="aboutusCancer" class="close" @click="closeDialog" />
      <div class="desc">
        {{ showDesc }}
      </div>
    </div>
    <FloatBar category="ielts"></FloatBar>
    <FloatAds></FloatAds>

  </div>
</template>

<script>
  import store from '@/store'
  import FloatBar from '@/components/FloatBar/FloatBar.vue'
  import FloatAds from '@/components/FloatAds/FloatAds.vue'

  export default {
    components: {
      FloatAds,
      FloatBar,
    },
    data () {
      return {
        showImg: '',
        showDesc: '',
        swiperBtn: false,
        isShow: false,
        swiperOption: {
          slidesPerView: 4,
          spaceBetween: 20,
          loop: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            bulletActiveClass: 'my-bullet-active',
            bulletClass: 'my-bullet',
          },
          autoplay: {
            delay: 2500,
            disableOnInteraction: false,
          },
        },
        index: '',
        picHead: this.$domain + 'image/ow/pc/',
        params: {
          index: '',
          img: '',
          imgList: [],
          desc: '',
        },
      }
    },
    mounted () {
    },
    activated () {
    },
    computed: {
      bannerImg () {
        return this.picHead + 'banner_pic.png'
      },
      bannerImg1 () {
        return this.picHead + 'band_pic.png'
      },
      bannerImg2 () {
        return this.picHead + 'band_pic1.png'
      },
      teacherImg () {
        return this.picHead + 'teacher_pic.png'
      },
      teampic1 () {
        return this.picHead + 'team_pic1.png'
      },
      teampic9 () {
        return this.picHead + 'team_pic9.png'
      },
      teampic2 () {
        return this.picHead + 'team_pic2.png'
      },
      teampic3 () {
        return this.picHead + 'team_pic3.png'
      },
      teampic4 () {
        return this.picHead + 'team_pic4.png'
      },
      teampic5 () {
        return this.picHead + 'team_pic5.png'
      },
      teampic6 () {
        return this.picHead + 'team_pic6.png'
      },
      teampic7 () {
        return this.picHead + 'team_pic7.png'
      },
      teampic8 () {
        return this.picHead + 'team_pic8.png'
      },
      arrowLeft () {
        return this.picHead + 'aboutus_arrow_left.png'
      },
      arrowRight () {
        return this.picHead + 'aboutus_arrow_right.png'
      },
      aboutusCancer () {
        return this.picHead + 'aboutus_cancer.png'
      },
      descLists () {
        var dataList = [
          '趴趴作为雅思考试官方（BC）的合作伙伴，我们曾多次与雅思官方进行密切交流，了解雅思考试新趋势、新资讯。为雅思考生的备考保驾护航。',
          '在留学考培方面，趴趴教育连续两年收到世界大学权威排名机构——英国QS教育集团官方邀请，参加QS全球巡展，并且在展会现场为学生提供留学、语言考试备考等咨询，受到与会学生的一致好评。',
          '同时，趴趴教育也曾与GMAT官方机构GMAC联名举行了2019-2020的官方备考指南全解读，趴趴的老师与GMAC大中华区的老师共同与帮助广大GMAT考生了解目前考情，更好地准备考试。',
          '2018、2019年度，趴趴教育在北京、杭州、南京等城市深入各大高校举行“趴趴教育上分趴”活动，雅思前考官Paul Winterbottom领衔地名师团们与雅思考生们一起交流、沟通问题，活动场场爆满，座无虚席，获得了考生们的一致好评。\n',
          '2018、2019年度，趴趴教育在北京、杭州、南京等城市深入各大高校举行“趴趴教育上分趴”活动，雅思前考官Paul Winterbottom领衔地名师团们与雅思考生们一起交流、沟通问题，活动场场爆满，座无虚席，获得了考生们的一致好评。\n',
          '2018、2019年度，趴趴教育在北京、杭州、南京等城市深入各大高校举行“趴趴教育上分趴”活动，雅思前考官Paul Winterbottom领衔地名师团们与雅思考生们一起交流、沟通问题，活动场场爆满，座无虚席，获得了考生们的一致好评。\n',
          '2018、2019年度，趴趴教育在北京、杭州、南京等城市深入各大高校举行“趴趴教育上分趴”活动，雅思前考官Paul Winterbottom领衔地名师团们与雅思考生们一起交流、沟通问题，活动场场爆满，座无虚席，获得了考生们的一致好评。\n',
          '2018、2019年度，趴趴教育在北京、杭州、南京等城市深入各大高校举行“趴趴教育上分趴”活动，雅思前考官Paul Winterbottom领衔地名师团们与雅思考生们一起交流、沟通问题，活动场场爆满，座无虚席，获得了考生们的一致好评。\n',
          '趴趴教育拥有自己的线下品牌活动——"PA PA SUPERDAY”，SUPERDAY已经在北京、上海顺利开展，并计划在更多城市举行。活动中广大考生们不仅可以面对面和喜爱地网红老师咨询沟通，更有海量礼品赠送和美食相伴，享受专属趴趴与考生们的欢乐一天~', // 8
          '趴趴教育拥有自己的线下品牌活动——"PA PA SUPERDAY”，SUPERDAY已经在北京、上海顺利开展，并计划在更多城市举行。活动中广大考生们不仅可以面对面和喜爱地网红老师咨询沟通，更有海量礼品赠送和美食相伴，享受专属趴趴与考生们的欢乐一天~', // 8
          '趴趴教育拥有自己的线下品牌活动——"PA PA SUPERDAY”，SUPERDAY已经在北京、上海顺利开展，并计划在更多城市举行。活动中广大考生们不仅可以面对面和喜爱地网红老师咨询沟通，更有海量礼品赠送和美食相伴，享受专属趴趴与考生们的欢乐一天~', // 8
          '趴趴教育拥有自己的线下品牌活动——"PA PA SUPERDAY”，SUPERDAY已经在北京、上海顺利开展，并计划在更多城市举行。活动中广大考生们不仅可以面对面和喜爱地网红老师咨询沟通，更有海量礼品赠送和美食相伴，享受专属趴趴与考生们的欢乐一天~', // 8
          '趴趴教育拥有自己的线下品牌活动——"PA PA SUPERDAY”，SUPERDAY已经在北京、上海顺利开展，并计划在更多城市举行。活动中广大考生们不仅可以面对面和喜爱地网红老师咨询沟通，更有海量礼品赠送和美食相伴，享受专属趴趴与考生们的欢乐一天~', // 8
          '趴趴教育拥有自己的线下品牌活动——"PA PA SUPERDAY”，SUPERDAY已经在北京、上海顺利开展，并计划在更多城市举行。活动中广大考生们不仅可以面对面和喜爱地网红老师咨询沟通，更有海量礼品赠送和美食相伴，享受专属趴趴与考生们的欢乐一天~', // 8
          '2018年度，趴趴教育荣获知名互联网教育公司沪江网校旗下的CCTALK平台颁发的「2018年度网师新生力量奖」，同时也代表其对趴趴教育的教育成果的极大认可。\n', //14
          '作为国内知名的问答社区，知乎曾联合趴趴多次进行了在线知识付费形式的新尝试，并且取得了非常良好的效果，合作愈发密切。', //15
          '趴趴教育也曾与「饿了么」联合举办活动，为广大大学生群里派送了海量福利，让他们享受到了身体食粮和精神食粮的双丰收~ ', //16
          '为了适应广大学生的学习需求以及让更多学生能够通过趴趴的帮助顺利圆梦留学，趴趴也在快速成长中。趴趴一直在努力，给各位更好质量的课程和服务！\n', //17
          '趴趴在“2019第二届亚洲经济大会”上，荣获2019年度【亚洲品牌知名度出国语言培训机构】、【亚洲家长信赖教育机构】奖项，大会由经济专家、全球各行业代表共同出席，说明趴趴已获得业界的高度认可。', //18
          '2019年度，趴趴荣获知名互联网教育公司沪江网校旗下的CCTALK平台颁发的【CCTalk年度优秀网师】奖项，再次获得业界极大认可和信任。',
        ]
        return dataList
      },
      actImgs () {
        var dataList = [
          this.picHead + 'activity-1-1.png',
          this.picHead + 'activity-2-1.png',
          this.picHead + 'activity-3-1.png',
          this.picHead + 'activity-4-1.png',
          this.picHead + 'activity-4-2.png',
          this.picHead + 'activity-4-3.png',
          this.picHead + 'activity-4-4.png',
          this.picHead + 'activity-4-5.png',
          this.picHead + 'activity-5-1.png', // 8
          this.picHead + 'activity-5-2.png',
          this.picHead + 'activity-5-3.png',
          this.picHead + 'activity-5-4.png',
          this.picHead + 'activity-5-5.png',
          this.picHead + 'activity-5-6.png',
          this.picHead + 'activity-6-1.png', // 14
          this.picHead + 'activity-7-1.png', // 15
          this.picHead + 'activity-8-1.png', // 16
          this.picHead + 'activity-9-1.png', // 17
          this.picHead + 'activity-10-1.png', // 18
          this.picHead + 'activity-11-1.png', //19
        ]
        return dataList
      },
      activities () {
        var activities = [
          {
            index: 0,
            img: this.picHead + 'activities_pic1.png',
            imgIndex: 0,
            desc: '趴趴作为雅思考试官方（BC）的合作伙伴，我们曾多次与雅思官方进行密切交流，了解雅思考试新趋势、新资讯。为雅思考生的备考保驾护航。',
          },
          {
            index: 1,
            img: this.picHead + 'activities_pic2.png',
            imgIndex: 1,
            desc: '在留学考培方面，趴趴教育连续两年收到世界大学权威排名机构——英国QS教育集团官方邀请，参加QS全球巡展，并且在展会现场为学生提供留学、语言考试备考等咨询，受到与会学生的一致好评。',
          },
          {
            index: 2,
            img: this.picHead + 'activities_pic3.png',
            imgIndex: 2,
            desc: '同时，趴趴教育也曾与GMAT官方机构GMAC联名举行了2019-2020的官方备考指南全解读，趴趴的老师与GMAC大中华区的老师共同与帮助广大GMAT考生了解目前考情，更好地准备考试。',
          },
          {
            index: 3,
            img: this.picHead + 'activities_pic4.png',
            imgIndex: 3,
            desc: '2018、2019年度，趴趴教育在北京、杭州、南京等城市深入各大高校举行“趴趴教育上分趴”活动，雅思前考官Paul Winterbottom领衔地名师团们与雅思考生们一起交流、沟通问题，活动场场爆满，座无虚席，获得了考生们的一致好评。\n',
          },
          {
            index: 4,
            img: this.picHead + 'activities_pic5.png',
            imgIndex: 8,
            desc: '趴趴教育拥有自己的线下品牌活动——"PA PA SUPERDAY”，SUPERDAY已经在北京、上海顺利开展，并计划在更多城市举行。活动中广大考生们不仅可以面对面和喜爱地网红老师咨询沟通，更有海量礼品赠送和美食相伴，享受专属趴趴与考生们的欢乐一天~',
          },
          {
            index: 5,
            img: this.picHead + 'activities_pic6.png',
            imgIndex: 14,
            desc: '2018年度，趴趴教育荣获知名互联网教育公司沪江网校旗下的CCTALK平台颁发的「2018年度网师新生力量奖」，同时也代表其对趴趴教育的教育成果的极大认可。\n',
          },
          {
            index: 6,
            img: this.picHead + 'activities_pic7.png',
            imgIndex: 15,
            desc: '作为国内知名的问答社区，知乎曾联合趴趴多次进行了在线知识付费形式的新尝试，并且取得了非常良好的效果，合作愈发密切。',
          },
          {
            index: 7,
            img: this.picHead + 'activities_pic8.png',
            imgIndex: 16,
            desc: '趴趴教育也曾与「饿了么」联合举办活动，为广大大学生群里派送了海量福利，让他们享受到了身体食粮和精神食粮的双丰收~ ',
          },
          {
            index: 8,
            img: this.picHead + 'activities_pic9.png',
            imgIndex: 17,
            desc: '为了适应广大学生的学习需求以及让更多学生能够通过趴趴的帮助顺利圆梦留学，趴趴也在快速成长中。趴趴一直在努力，给各位更好质量的课程和服务！\n',
          },
          {
            index: 9,
            img: this.picHead + 'activities_pic10.png',
            imgIndex: 18,
            desc: '趴趴在“2019第二届亚洲经济大会”上，荣获2019年度【亚洲品牌知名度出国语言培训机构】、【亚洲家长信赖教育机构】奖项，大会由经济专家、全球各行业代表共同出席，说明趴趴已获得业界的高度认可。',
          },
          {
            index: 10,
            img: this.picHead + 'activities_pic11.png',
            imgIndex: 19,
            desc: '2019年度，趴趴荣获知名互联网教育公司沪江网校旗下的CCTALK平台颁发的【CCTalk年度优秀网师】奖项，再次获得业界极大认可和信任。',
          },
        ]

        return activities
      },
    },
    methods: {
      mouseOverSlider () {
        this.swiperBtn = true
      },
      mouseLeaveSlider () {
        this.swiperBtn = false
      },
      getMax ( param ) {
        this.params = param
        console.log(param.imgIndex)
        this.showImg = this.actImgs[ param.imgIndex ]
        this.showDesc = this.descLists[ param.imgIndex ]
        this.isShow = true
        this.index = param.imgIndex
      },
      closeDialog () {
        this.isShow = false
      },
      leftclick ( param ) {
        // -1 为0不能点 actImgs
        var self = this
        if (self.index) {
          var index = parseInt(self.index) - 1
          this.index = index
          this.showImg = this.actImgs[ index ]
          this.showDesc = this.descLists[ index ]
        } else {
          self.index = 19
          this.showImg = this.actImgs[ self.index ]
          this.showDesc = this.descLists[ self.index ]
        }
      },
      rightclick ( param ) {
        var self = this
        // +1 为19不能点击 actImgs
        if (self.index) {
          if (self.index == 19) {
            self.index = 0
            self.showImg = this.actImgs[ self.index ]
            self.showDesc = this.descLists[ self.index ]
          } else {
            var index = parseInt(self.index) + 1
            self.index = index
            self.showImg = this.actImgs[ index ]
            self.showDesc = this.descLists[ index ]
          }
        } else {
          var index = parseInt(self.index) + 1
          self.index = index
          self.showImg = this.actImgs[ index ]
          self.showDesc = this.descLists[ index ]
        }
      },
    },

    watch: {
      isShow: function ( a, b ) {
        if (a) {
          // 禁止滑动
          document.documentElement.style.overflow = 'hidden'
        } else {
          // 可以滑动
          document.documentElement.style.overflow = 'scroll'
        }
      },
    },
  }
</script>
<style>
  .my-bullet.my-bullet-active {
    background: #29d087;
    width: 50px;
    height: 8px;
    border-radius: 4px;
    opacity: 1;
  }

  .my-bullet {
    /*background: red;*/
    width: 30px;
    height: 8px;
    display: inline-block;
    border-radius: 4px;
    background: #e6e6e6;
    opacity: 1;
    margin: 0 12px;
  }

  .swiper-container {
    padding-bottom: 57px;
  }
</style>
<style lang="less" scoped>
  .about-us {
    font-family: PingFang SC;
    position: relative;

    & > .wrap-max-bg {
      position: fixed;
      background: #000;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 999;
      opacity: 0.5;
    }

    & > .wrap-max {
      width: 860px;
      height: 610px;
      background: #333333;
      border-radius: 4px;
      left: calc((100% - 860px) / 2);
      position: fixed;
      bottom: 10px;
      z-index: 1000;

      & > .rightArrow {
        background: #5150508f;
        width: 57px;
        height: 57px;
        border-radius: 50%;
        position: absolute;
        bottom: 272px;
        right: 20px;

        & > img {
          position: relative;
          top: 17px;
        }
      }

      & > .leftArrow {
        background: #5150508f;
        width: 57px;
        height: 57px;
        border-radius: 50%;
        position: absolute;
        bottom: 272px;
        left: 20px;

        & > img {
          position: relative;
          top: 17px;
        }
      }

      & > .grey {
        opacity: 0.3;
      }

      & > .close {
        position: absolute;
        right: 18px;
        top: 18px;
      }

      & > .maxImg {
        position: absolute;
        top: 31px;
        left: 63px;
      }

      & > .desc {
        position: absolute;
        bottom: 0;
        left: 0;
        color: #ffffff;
        font-size: 12px;
        padding: 14px 27px 23px 22px;
        width: calc(100% - 49px);
        background: #1c18012e;
        left: 0;
        text-align: left;
      }
    }

    & > .about-us-banner {
      & > .about-us-banner-title {
        font-size: 32px;
        font-weight: bold;
        color: #333333;
        line-height: 42px;
        position: absolute;
        top: 110px;
        display: inline-block;
        width: 100%;
        left: 0;

        & > span {
          position: absolute;
          left: 0;
          width: 100%;
        }
      }

      & > .about-us-banner-content {
        font-size: 18px;
        color: #555555;
        top: 156px;
        position: absolute;
        display: inline-block;
        text-align: center;
        width: 100%;
        left: 0;

        & > span {
          width: 100%;
          display: inline-block;
          position: absolute;
          left: 0;
          text-align: center;
        }
      }
    }

    & > .about-us-introduce {
      margin-top: 80px;

      & > .about-us-introduce-title {
        font-size: 30px;
        font-family: PingFang SC;
        color: #333333;
        line-height: 30px;
      }

      & > .about-us-introduce-desc {
        color: #999999;
        font-size: 16px;
        line-height: 16px;
        margin-top: 13px;
      }

      & > .about-us-introduce-wrap {
        display: flex;
        justify-content: space-between;
        width: 1195px;
        margin: 0 auto;
        margin-top: 58px;

        .swiper-bottom-info {
          display: inline-block;

          & > img {
            border-radius: 6px;
            margin-bottom: 18px;
            font-size: 0;
          }

          & > .swiper-bottom-content {
            color: #555555;
            font-size: 14px;
            text-align: justify;
            height: 59px;
            width: 280px;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
          }
        }

        & > .about-us-introduce-middle {
          & > .about-us-introduce-middle-top {
            border: 1px solid #e5e5e5;
            display: flex;
            justify-content: space-between;


            & > div:nth-child(1) {
              /*padding: 30px 20px;*/
              color: #555555;
              font-size: 14px;
              text-align: justify;
              line-height: 25px;

              & > div {
                padding: 20px 20px 0px;
                height: 100%;
              }
            }
          }

          & > .about-us-introduce-middle-bottom {
            display: flex;
            justify-content: space-between;
            align-items: start;
            margin-top: 29px;

            img {
              width: 45%;
            }
          }
        }

        & > .about-us-introduce-left {
          width: 575px;

          & > .about-us-introduce-img {
            img {
              width: 100%;
            }
          }

          & > .about-us-introduce-content {
            text-align: justify;
            color: #555555;
            line-height: 28px;
            font-size: 14px;
            font-family: PingFang SC;
          }

          & > .about-us-img-info {
            & > .about-us-img-content {
              color: #555555;
              font-size: 14px;
              font-family: PingFang SC;
              line-height: 28px;
              text-align: justify;
              margin-bottom: 13px;
            }

            & > .about-us-img {
              margin-bottom: 14px;
              font-size: 0px;
            }

            & > .about-us-img-desc {
              color: #999999;
              margin-bottom: 7px;
              font-size: 12px;
            }
          }
        }

        & > .about-us-introduce-right {
          width: 575px;

          & > .about-us-introduce-content {
            text-align: justify;
            color: #555555;
            line-height: 28px;
            font-size: 14px;
            font-family: PingFang SC;
          }

          & > .about-us-introduce-top-title {
            color: #555555;
            font-size: 16px;
            font-weight: bold;
            font-family: PingFang SC;
            text-align: left;
            margin-bottom: 15px;
          }

          & > .about-us-introduce-img {
            text-align: left;
            margin-top: 12px;

            & > .tmall {
              display: flex;
            }

            & > .tmall > img:nth-of-type(1) {
              width: 260px;
              display: inline-block;
              margin-right: 18px;
            }

            & > .tmall > img:nth-of-type(2) {
              width: 308px;
              height: 157px;
              display: inline-block;
              margin-right: 18px;
            }

            & > img {
              display: inline-block;
              margin-right: 18px;
            }
          }
        }
      }
    }
  }
</style>
